import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/logo.png";
import { Call } from "@mui/icons-material";

export const BritishLifeDEMO = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [leadState, setLeadState] = useState("");
  const [country, setCountry] = useState("gb");
  const [country2, setCountry2] = useState("gb");

  const [companyName, setCompanyName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [callType, setCallType] = useState("ad_call_british_female"); // State for phone call type

  const [testV2DropdownOption, setTestV2DropdownOption] =
    useState("british_life_leads");
  const [testV2FirstName, setTestV2FirstName] = useState("");
  const [testV2City, setTestV2City] = useState("");
  const [testV2InsuranceType, setTestV2InsuranceType] = useState("");
  const [testV2CustomerInterest, setTestV2CustomerInterest] = useState("");
  const [testV2IntroducerOrWebsite, setTestV2IntroducerOrWebsite] =
    useState("");
  const [testV2PhoneNumber, setTestV2PhoneNumber] = useState("");
  const [testV2LiveTransferPhoneNumber, setTestV2LiveTransferPhoneNumber] =
    useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const human_agent_first_name = "Tom";

    // const fromNumber = "12392081459";
    const fromNumber = "18788844965";
    // const fromNumber = "15489185087";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";
    let human_agent_id = "0ho90QkFDmsLuKGZ4iAv";
    let lead_id = "123";
    let agent_id = "agent_6267be033ac8661249a4fff235";

    let lead_first_name = testV2FirstName;
    let lead_city = "London";
    let human_agent_company_name = "British Life";
    let human_agent_number = `+${testV2LiveTransferPhoneNumber}`;
    let lead_type = testV2DropdownOption;
    let introducer_or_website = "britishlife.com";
    let insurance_type = testV2InsuranceType;
    // let customer_interest = "interested in long-term tax savings";
    let customer_interest = `interested in ${testV2CustomerInterest}`;
    let lead_tz = "America/Chicago";
    let human_agent_tz = "America/New_York";
    let to_number = testV2PhoneNumber;

    await axios
      .request(
        `${baseUrl}?agent_id=${agent_id}&lead_first_name=${lead_first_name}&human_agent_company_name=${human_agent_company_name}&lead_city=${lead_city}&human_agent_id=${human_agent_id}&lead_id=${lead_id}&human_agent_number=${human_agent_number}&human_agent_first_name=${human_agent_first_name}&introducer_or_website=${introducer_or_website}&insurance_type=${insurance_type}&customer_interest=${customer_interest}&from_number=${fromNumber}&to_number=${to_number}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": "69420",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => window.alert("Call initiated successfully!"));
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>British Life DEMO</h1>
          <h3>Testing website for JasperVOCAL's British Life Calling Script</h3>
          <form onSubmit={handleSubmit}>
            <Select
              value={testV2DropdownOption}
              onChange={(e) => {
                setTestV2DropdownOption(e.target.value);
              }}
              required
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="british_life_leads">
                Google Leads - Life Insurance/Protection
              </MenuItem>
            </Select>
            <>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                value={testV2FirstName}
                onChange={(e) => setTestV2FirstName(e.target.value)}
              />
              {/* <TextField
                autoFocus
                margin="dense"
                label="Introducer or Website"
                type="text"
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                value={testV2IntroducerOrWebsite}
                onChange={(e) => setTestV2IntroducerOrWebsite(e.target.value)}
              /> */}
              <TextField
                autoFocus
                margin="dense"
                label="Insurance Type"
                type="text"
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                value={testV2InsuranceType}
                onChange={(e) => setTestV2InsuranceType(e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                label="Customer Interest (e.g. long-term tax savings)"
                type="text"
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                value={testV2CustomerInterest}
                onChange={(e) => setTestV2CustomerInterest(e.target.value)}
              />
            </>
            <p>
              <b>Live transfer phone number</b>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country2}
                    onChange={(e) => setCountry2(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country2}
                    value={testV2LiveTransferPhoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setTestV2LiveTransferPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <p>
              <b>Your phone number</b>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={testV2PhoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setTestV2PhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              After clicking to receive the DEMO call, you will receive a call
              from the following phone number: <b>+1 (878) 884-4965</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Click here to receive the DEMO call
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
};
