import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AutoLlamadas from "./pages/AutoLlamadas";
import _3Common from "./pages/3Common";
import LambertNemec from "./pages/LambertNemec";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import OutreachGenius from "./pages/OutreachGenius";
import NorsemenAuction from "./pages/NorsemenAuction";
import Espana from "./pages/Espana";
import Colombia from "./pages/Colombia";
import { InsuranceDemo } from "./pages/insurance/InsuranceDemo";
import { BishopAndQuerel } from "./pages/real-estate/BishopAndQuerel";
import {
  LifeInsuranceDEMO,
  MedicareInsuranceDEMO,
} from "./pages/insurance/insurance-demo";
import { TapetesSaoCarlos } from "./pages/brasil/tapetes-sao-carlos-demo";
import { AgentAutopilotDEMOEspanol } from "./pages/spain/agent-autopilot-demo-espanol";
import { BritishLifeDEMO } from "./pages/insurance/british-life-demo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/auto-llamadas" element={<AutoLlamadas />} />
        <Route path="/3common" element={<_3Common />} />
        <Route path="/outreach-genius" element={<OutreachGenius />} />
        <Route path="/lambert-nemec" element={<LambertNemec />} />
        <Route path="/norsemen-auction" element={<NorsemenAuction />} />
        <Route path="/espana" element={<Espana />} />
        <Route path="/colombia" element={<Colombia />} />
        <Route path="/insurance-lead-magnet-demo" element={<InsuranceDemo />} />
        <Route path="/insurance" element={<MedicareInsuranceDEMO />} />
        <Route path="/bishop-querel" element={<BishopAndQuerel />} />
        <Route path="/tapetes-sao-carlos" element={<TapetesSaoCarlos />} />
        <Route
          path="/agent-autopilot-espanol"
          element={<AgentAutopilotDEMOEspanol />}
        />
        <Route path="/british-life" element={<BritishLifeDEMO />} />
      </Routes>
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
